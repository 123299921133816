import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/que-es-bidbike',
    name: '¿Qué es Bid Bike?',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/guia-vendedores',
    name: 'Guía para vendedores',
    component: () => import('../views/SellersInfo.vue')
  },
  {
    path: '/para-profesionales',
    name: 'Para profesionales',
    component: () => import('../views/ProfesionalInfo.vue')
  },
  {
    path: '/por-que-bidbike',
    name: '¿Por qué Bid Bike?',
    component: () => import('../views/WhyBidBike.vue')
  },
  {
    path: '/aviso-legal',
    name: 'Avíso legal',
    component: () => import('../views/LegalAdvise.vue')
  },
  {
    path: '/condiciones-generales',
    name: 'Condiciones generales',
    component: () => import('../views/GeneralConditions.vue')
  },
  {
    path: '/politica-de-privacidad',
    name: 'Política de privacidad',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/politica-de-cookies',
    name: 'Política de cookies',
    component: () => import('../views/CookiesPolicy.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/registro-profesionales',
    name: 'Registro profesional',
    component: () => import('../views/ProfesionalSignupView.vue')
  },
  {
    path: '/profesional-no-validado',
    name: 'Profesional no validado',
    component:()=>import('../views/ProfesionalNotValidated.vue')
  },
  {
    path: '/vender-mi-moto',
    name: 'Vender mi moto',
    component: () => import('../views/SellFormView.vue')
  },
  {
    path: '/subir-mi-moto/:aid',
    name: 'Vender mi moto',
    component: () => import('../views/SellFormView.vue')
  },
  {
    path: '/listado-de-motos',
    name: 'Listado de motos',
    component: () => import('../views/ListView.vue')
  },
  {
    path: '/moto-subida/:nombre',
    name: 'Finalizar moto',
    component: () => import('../views/Congrats.vue')
  },
  {
    path: '/moto/:slug/:i/:nombre',
    name: 'Moto',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/documentacion/:aid',
    name: 'Documentación',
    component: () => import('../views/DocumentationView.vue')
  },
  {
    path: '/panel/motos',
    name: 'Motos',
    component: () => import('../views/dashboard/AdsView.vue')
  },
  {
    path: '/panel/ofertas',
    name: 'Motos',
    component: () => import('../views/dashboard/OffersView.vue')
  },
  {
    path: '/panel/clientes',
    name: 'Clientes',
    component: () => import('../views/dashboard/ClientsView.vue')
  },
  {
    path: '/panel/marcas',
    name: 'Marcas',
    component: () => import('../views/dashboard/BrandsView.vue')
  },
  {
    path: '/panel/modelos/:bid',
    name: 'Modelos',
    component: () => import('../views/dashboard/ModelView.vue')
  },
  {
    path: '/panel/emails',
    name: 'EMails',
    component: () => import('../views/dashboard/MailsView.vue')
  },
  {
    path: '/panel/newsletter',
    name: 'Newsletter',
    component: () => import('../views/dashboard/NewsView.vue')
  },
  {
    path: '/panel/estadisticas',
    name: 'Estadísticas',
    component: () => import('../views/dashboard/ChartsView.vue')
  },
  {
    path: '/panel/tarifas',
    name: 'Tarifas',
    component: () => import('../views/dashboard/TarifasView.vue')
  },
  {
    path: '/panel/contenido',
    name: 'Contenido',
    component: () => import('../views/dashboard/ContentView.vue')
  },
  {
    path: '/panel/editar-moto/:i/:aid',
    name: 'Editar moto',
    component: () => import('../views/dashboard/EditAd.vue')
  },
  {
    path: '/panel/editar-foto/:i/:aid/:index',
    name: 'Editar',
    component: () => import('../views/dashboard/EditView.vue')
  },
  {
    path: '/panel/mi-cuenta',
    name: 'Mi cuenta',
    component: () => import('../views/dashboard/ProfileView.vue')
  },
  {
    path: '/panel/editar-usuario/:rol/:uid',
    name: 'Editar usuario',
    component: () => import('../views/dashboard/UserView.vue')
  },
  {
    path: '/panel/ver-usuario/:uid',
    name: 'Ver usuario',
    component: () => import('../views/dashboard/UserProfile.vue')
  },
  {
    path: '/panel/envios',
    name: 'Envíos',
    component: () => import('../views/dashboard/ShipmentView.vue')
  },
  {
    path: '/contrato/:slug/:i/:adid',
    name: 'Contrato',
    component: () => import('../views/ContractView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/* eslint-disable */
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});

router.beforeEach((to, from, next) => {
  const whatsappLink = document.querySelector('a.whatsapp');
  if (whatsappLink) {
    whatsappLink.style.display = 'flex'; 
  }
  next();
})
export default router

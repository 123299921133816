<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app light style="background: white"
      v-if="$route.path.includes('/panel') && !isMobile">
      <v-list dense>
        <v-list-item-group>
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-dialog v-model="dialogclose" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-action>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Cerrar sesión
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title style="background-color:rgb(255, 208, 0)" class="text-h5 lighten-2">
                Cerrar sesión
              </v-card-title>
              <v-card-text>
                ¿Deseas salir de tu cuenta?
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="rgb(255, 208, 0)" @click="logout()">
                  Sí
                </v-btn>
                <v-btn color="gray" outlined @click="dialogclose = false">
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :color="bg" :clipped-left="$vuetify.breakpoint.lgAndUp" app height="85" elevation="1"
      :class="greenMode ? 'bidheader greenMode' : 'bidheader'" style="max-width:1920px; margin:0 auto">
      <v-menu bottom left class="mobiledashmenu" v-if="isMobile && $route.path.includes('/panel')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon :color="greenMode ? '#ffffff' : '#000000'">mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list class="mobilemenudash">
          <v-list-item v-for="(item, i) in items" :key="i" :to="item.route">
            <v-list-item-action>
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-dialog v-model="dialogclose" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-action>
                  <v-icon>mdi-exit-to-app</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Cerrar sesión
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title style="background-color:rgb(255, 208, 0)" class="text-h5 lighten-2">
                Cerrar sesión
              </v-card-title>
              <v-card-text>
                ¿Deseas salir de tu cuenta?
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="rgb(255, 208, 0)" @click="logout()">
                  Sí
                </v-btn>
                <v-btn color="gray" outlined @click="dialogclose = false">
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list>
      </v-menu>
      <v-toolbar-title>
        <router-link to="/">
          <img :src="greenMode ? '/assets/img/bidbike_logo_white.png' : '/assets/img/bidbike_logo_green.png'" alt="Logo"
            class="logo">
        </router-link>
      </v-toolbar-title>
      <v-spacer v-if="!isMobile || (isMobile && !isDash)"></v-spacer>
      <div class="hidden-sm-and-down">
        <router-link to="/que-es-bidbike"><v-btn text>Quiénes somos</v-btn></router-link>
        <router-link to="/para-profesionales"> <v-btn text>Para profesionales</v-btn></router-link>
        <router-link to="/listado-de-motos" v-if="canShowList()">
          <v-btn text>Listado de motos</v-btn>
        </router-link>
        <router-link v-if="!canShow()" to="/registro-profesionales">
          <v-btn text>Registro profesionales</v-btn>
        </router-link>
        <router-link :to="getRoute()">
          <v-btn text :class="{ 'supplantName': supplant }"><v-icon v-if="supplant">mdi-hat-fedora</v-icon>{{
            accessBTNname
            }}</v-btn>
        </router-link>
        <v-btn text v-icon v-if="supplant" @click="exitsupplant">Volver a mi cuenta</v-btn>
        <v-btn @click="sellMotorbike()" rounded :dark="!greenMode" :color="greenMode ? '#ffffff' : '#43b02a'"
          class="greenBTNSell">Vende
          tu moto</v-btn>
      </div>
      <v-btn outlined @click="sellMotorbike()" :color="greenMode ? '#ffffff' : '#43b02a'" class="sellmobmot"
        v-if="isMobile && isDash">Vende tu moto</v-btn>
      <v-menu bottom left class="mobilemenu" v-if="isMobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon :color="greenMode ? '#ffffff' : '#000000'">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <router-link to="/que-es-bidbike">
            <v-list-item link>
              <v-list-item-title dark>Quiénes somos</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/para-profesionales">
            <v-list-item link>
              <v-list-item-title>Para profesionales</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/listado-de-motos">
            <v-list-item link v-if="canShowList()">
              <v-list-item-title>Listado de motos</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/registro-profesionales">
            <v-list-item link v-if="!canShow()">
              <v-list-item-title>Registro profesionales</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link :to="getRoute()">
            <v-list-item link>
              <v-list-item-title>{{ accessBTNname }}</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-btn text v-icon v-if="supplant" dark @click="exitsupplant">Volver a mi cuenta</v-btn>
          <v-list-item link class="sellbtnmob">
            <v-list-item-title @click="sellMotorbike()">Vende tu moto</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container fluid style="padding:0; margin-top: 0px;">
        <router-view />
      </v-container>
    </v-main>
    <v-footer dark :class="isMobile ? 'footcard' : $route.path.includes('panel') ? 'footcard panelfoot' : 'footcard'"
      style="padding-bottom: 15px;">
      <v-row align="start" class="align-start" v-if="showFooter">
        <v-col md="3" style="text-align:center">
          <img src="/assets/img/bidbike_logo_white.png" alt="Logo" width="170" />
        </v-col>
        <v-col md="2">
          <v-subheader>
            <h2>Ayuda</h2>
          </v-subheader>
          <v-list color="transparent">
            <router-link to="/guia-vendedores"><v-list-item>Consejos para vendedores</v-list-item></router-link>
          </v-list>
        </v-col>
        <v-col md="2">
          <v-subheader>
            <h2>Información</h2>
          </v-subheader>
          <v-list color="transparent">
            <router-link to="/que-es-bidbike"><v-list-item>¿Qué es Bidbike?</v-list-item></router-link>
            <router-link to="/por-que-bidbike"><v-list-item>¿Por qué Bidbike?</v-list-item></router-link>
            <router-link to="/para-profesionales"><v-list-item>Para profesionales</v-list-item></router-link>
          </v-list>
        </v-col>
        <v-col md="2">
          <v-subheader>
            <h2>Enlaces</h2>
          </v-subheader>
          <v-list color="transparent">
            <router-link to="/aviso-legal"><v-list-item>Aviso Legal</v-list-item></router-link>
            <router-link to="/condiciones-generales"><v-list-item>Condiciones generales</v-list-item></router-link>
            <router-link to="/politica-de-privacidad"><v-list-item>Privacidad</v-list-item></router-link>
            <router-link to="/politica-de-cookies"><v-list-item>Política de cookies</v-list-item></router-link>
          </v-list>
        </v-col>
        <v-col md=2>
          <v-subheader>
            <h2>Contacto</h2>
          </v-subheader>
          <v-list color="transparent">
            <v-list-item><a style="color:white" href="mailto:contacto@bidbike.es">contacto@bidbike.es</a></v-list-item>
            <v-list-item><a style="color:white"
                href="https://api.whatsapp.com/send?phone=34648187979">648187979</a></v-list-item>
          </v-list>
        </v-col>
        <v-col cols="12" style="text-align:center">
          <strong> © {{ new Date().getFullYear() }} Copyright Bidbike</strong>
        </v-col>
      </v-row>
      <v-row align="start" class="align-start" v-else>
        <v-col cols="12" style="text-align:center">
          <strong> © {{ new Date().getFullYear() }} Copyright Bidbike</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  updated() {
    if (this.$route.path == '/') this.showApp = false
    else this.showApp = true
    if (this.$route.path == '/listado-de-motos' || this.$route.path.includes("/moto/") || this.$route.path.includes("/panel")) {
      this.bg = "#43b02a"
      this.greenMode = true
    } else this.greenMode = false
    this.getRoute()
    this.getName()
    this.refreshItems()
  },
  created() {
    if (this.$route.path == '/listado-de-motos' || this.$route.path.includes("/moto/") || this.$route.path.includes("/panel")) {
      this.bg = "#43b02a"
      this.greenMode = true
    } else this.greenMode = false
    this.getRoute()
    this.getName()
    this.refreshItems()
    if (this.$route.path.includes('vender-mi-moto')) {
      if (this.isMobile)
        this.showFooter = false
      else this.showFooter = true
    } else this.showFooter = true
  },
  mounted() {
    this.getRoute()
    window.onscroll = () => {
      this.changeColor();
    };
    this.getName()
    this.refreshItems()
    this.$root.$on('userLoggedIn', () => {
      this.refreshItems();
    });
    if (this.$session.get('sup') == true) this.supplant = true
    else {
      if (this.$session.exists())
        this.refreshLogin()
    }
    if (this.$route.path == '/listado-de-motos' || this.$route.path.includes("/moto/") || this.$route.path.includes("/panel")) {
      this.bg = "#43b02a"
      this.greenMode = true
    } else this.greenMode = false

  },
  watch: {
    $route(to) {
      this.getRoute()
      this.getName()
      if (to.path == '/listado-de-motos' || to.path.includes("/contrato") || to.path.includes("/moto/") || to.path.includes("/panel")) {
        if (this.$session.exists()) {
          if (this.$session.get('sup') == true) this.supplant = true
          this.bg = "#43b02a"
          this.greenMode = true
        }
        else {
          this.$router.push('/login');
        }
      } else {
        this.greenMode = false
        this.bg = "#ffffff"
      }
      if (to.path.includes('vender-mi-moto')) {
        if (this.isMobile)
          this.showFooter = false
        else this.showFooter = true
      } else this.showFooter = true
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';
    },
    isDash() {
      return this.$route.path == '/'
    },
    displayName() {
      if (this.$session.exists() || localStorage.getItem('name') !== null) {
        return this.$session.get('name');
      } else {
        return 'Acceso';
      }
    }
  },

  data: () => ({
    accessBTNname: 'Acceso',
    bg: '#ffffff',
    showApp: false,
    showFooter: true,
    logged: false,
    drawer: true,
    dialogclose: false,
    greenMode: false,
    supplant: false,
    items: (this.$session.get('rol') === '3' || this.$session.get('rol') === 3) ? [{ icon: 'mdi-motorbike', text: 'Motos', route: '/panel/motos' },
    { icon: 'mdi-account-group', text: 'Usuarios', route: '/panel/clientes' }, { icon: 'mdi-list-box-outline', text: 'Marcas', route: '/panel/marcas' },
    { icon: 'mdi-email-outline', text: 'E-Mails automáticos', route: '/panel/emails' }, { icon: 'mdi-email-fast-outline', text: 'Newsletter', route: '/panel/newsletter' }, { icon: 'mdi-truck-cargo-container', text: 'Envios', route: '/panel/envios' }, { icon: 'mdi-cash-multiple', text: 'Tarifas', route: '/panel/tarifas' },
    { icon: 'mdi-chart-areaspline', text: 'Estadísticas', route: '/panel/estadisticas' }, { icon: 'mdi-file-document-minus-outline', text: 'Contenido', route: '/panel/contenido' }, { icon: 'mdi-account-outline', text: 'Mi cuenta', route: '/panel/mi-cuenta' }
    ] : (this.$session.get('rol') === '2' || this.$session.get('rol') === 2) ? [{ icon: 'mdi-motorbike', text: 'Mis motos', route: '/panel/motos' }] : [{ icon: 'mdi-motorbike', text: 'Mis ofertas', route: '/panel/ofertas' }, { icon: 'mdi-motorbike', text: 'Mis motos', route: '/panel/motos' }]
  }),
  methods: {
    exitsupplant() {
      this.axios.get(this.api_url + '/admin/supplantuser/' + this.$session.get('u'), {
        headers: {
          'authorization': this.$session.get('originaltoken')
        }
      }).then(response => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('rol', response.data.rol);
        localStorage.setItem('name', response.data.name);
        localStorage.setItem('sname', response.data.sname);
        localStorage.setItem('s', response.data.status);
        localStorage.setItem('sup', false)
        this.$session.set('token', localStorage.getItem('token'))
        this.$session.set('rol', localStorage.getItem('rol'))
        this.$session.set('name', localStorage.getItem('name'))
        this.$session.set('sname', localStorage.getItem('sname'))
        this.$session.set('s', localStorage.getItem('s'))
        this.$session.set('sup', false)
        location.reload();
      })
    },
    refreshLogin() {
      this.axios.get(this.api_url + '/client/updatelastlogin', {
        headers: {
          'authorization': this.$session.get('token')
        }
      }).then(() => { })
    },
    refreshItems() {
      this.items = (this.$session.get('rol') === '3' || this.$session.get('rol') === 3) ? [{ icon: 'mdi-motorbike', text: 'Motos', route: '/panel/motos' },
      { icon: 'mdi-account-group', text: 'Usuarios', route: '/panel/clientes' }, { icon: 'mdi-list-box-outline', text: 'Marcas', route: '/panel/marcas' },
      { icon: 'mdi-email-outline', text: 'E-Mails automáticos', route: '/panel/emails' }, { icon: 'mdi-email-fast-outline', text: 'Newsletter', route: '/panel/newsletter' }, { icon: 'mdi-truck-cargo-container', text: 'Envios', route: '/panel/envios' }, { icon: 'mdi-cash-multiple', text: 'Tarifas', route: '/panel/tarifas' },
      { icon: 'mdi-chart-areaspline', text: 'Estadísticas', route: '/panel/estadisticas' }, { icon: 'mdi-file-document-minus-outline', text: 'Contenido', route: '/panel/contenido' }, { icon: 'mdi-account-outline', text: 'Mi cuenta', route: '/panel/mi-cuenta' }
      ] : (this.$session.get('rol') === '1' || this.$session.get('rol') === 1) ?
        [{ icon: 'mdi-motorbike', text: 'Mis ofertas', route: '/panel/ofertas' }, { icon: 'mdi-motorbike', text: 'Mis motos', route: '/panel/motos' }, { icon: 'mdi-account-outline', text: 'Mi cuenta', route: '/panel/mi-cuenta' }] :
        [{ icon: 'mdi-motorbike', text: 'Mis motos', route: '/panel/motos' }, { icon: 'mdi-account-outline', text: 'Mi cuenta', route: '/panel/mi-cuenta' }]

    },
    sellMotorbike() {
      if (this.$route.path === '/vender-mi-moto') window.location.reload();
      else this.$router.push('/vender-mi-moto')
    },
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.bg = '#ffffffEE';
      } else {
        this.bg = '#fff';
      }
    },
    getName() {
      if (this.$session.exists() || localStorage.getItem('name') != null)
        this.accessBTNname = this.$session.get('name')
      else this.accessBTNname = 'Acceso'
    },
    getRoute() {
      if (this.$session.exists()) {
        if (this.$session.get('rol') === '1') return '/panel/ofertas'
        else return '/panel/motos'
      }
      else if (localStorage.getItem('token') != null) {
        this.$session.start();
        this.$session.set('token', localStorage.getItem('token'))
        this.$session.set('originaltoken', localStorage.getItem('originaltoken'))
        this.$session.set('rol', localStorage.getItem('rol'))
        this.$session.set('name', localStorage.getItem('name'))
        this.$session.set('sname', localStorage.getItem('sname'))
        this.$session.set('s', localStorage.getItem('s'))
        this.$session.set('u', localStorage.getItem('u'))
      }
      else return '/login'
    },
    logout() {
      this.$session.destroy();
      localStorage.removeItem('token');
      localStorage.removeItem('originaltoken');
      localStorage.removeItem('rol');
      localStorage.removeItem('name');
      localStorage.removeItem('sname');
      localStorage.removeItem('s');
      localStorage.removeItem('u');
      this.$router.push('/login');
      location.reload();
    },
    canShow() {
      return this.$session.exists()
    },
    canShowList() {
      return this.$session.exists() && this.$session.get('rol') != 2
    }
  }
};
</script>
<style>
a:hover {
  text-decoration: none;
}

.bidheader {
  margin-bottom: 0;
  padding: 0 5%;
}

.bidheader a {

  font-family: n27regular;
}


.bidheader span {
  font-size: 12pt;
  text-transform: none
}

.router-link-exact-active span {
  color: #43b02a
}

.greenMode .router-link-exact-active span {
  color: #ffff;
}

.router-link-exact-active .greenBTNSell span {
  color: white
}

.footcard {
  padding-top: 30px;
}

.panelfoot {
  padding-left: 14rem;
}

.footcard a {
  font-family: 'n27regular';
}

.supplantName span {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

@media(max-width:1024px) {
  .logo {
    width: 180px !important;
  }

  .bidheader {
    padding: 0
  }

  .greenMode {
    padding-left: 5%;
  }

  .footcard {
    padding-top: 10px;
  }

  .mobilemenu .v-menu__content {
    width: 55%;
    border-radius: 15px;
    z-index: 99999 !important;
  }

  .v-menu__content .v-list {
    background: #000000DD;
    border-radius: 15px
  }

  .v-list .v-list-item--active .v-icon {
    color: green
  }

  .v-toolbar__content {
    padding: 0 7% 0 0
  }

  .v-list-item__title {
    font-family: n27regular;
    text-transform: capitalize;
    color: white;
    text-align: center;
  }

  .mobilemenudash .v-list-item__title {
    font-family: n27regular;
    text-transform: capitalize;
    color: white;
    text-align: left;
  }

  .sellbtnmob {
    background: #43b02a;
    margin: 0 10px 0 10px;
    border-radius: 20px;
  }

  .sellbtnmob .v-list-item__title {
    color: white;
  }

  .sellmobmot {
    border-radius: 25px;
    padding: 0 5px !important;
    margin: 0 auto;
  }
}
</style>
